// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/Users/ryan/Documents/website-sharkdreams/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tag-js": () => import("/Users/ryan/Documents/website-sharkdreams/src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-category-js": () => import("/Users/ryan/Documents/website-sharkdreams/src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-pages-404-js": () => import("/Users/ryan/Documents/website-sharkdreams/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/ryan/Documents/website-sharkdreams/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("/Users/ryan/Documents/website-sharkdreams/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("/Users/ryan/Documents/website-sharkdreams/src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("/Users/ryan/Documents/website-sharkdreams/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/Users/ryan/Documents/website-sharkdreams/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("/Users/ryan/Documents/website-sharkdreams/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-js": () => import("/Users/ryan/Documents/website-sharkdreams/src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-terms-of-use-js": () => import("/Users/ryan/Documents/website-sharkdreams/src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

exports.data = () => import("/Users/ryan/Documents/website-sharkdreams/.cache/data.json")

