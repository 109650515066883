module.exports = [{
      plugin: require('/Users/ryan/Documents/website-sharkdreams/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-89536651-1","head":true},
    },{
      plugin: require('/Users/ryan/Documents/website-sharkdreams/node_modules/gatsby-plugin-nprogress/gatsby-browser'),
      options: {"plugins":[],"color":"#92278f"},
    },{
      plugin: require('/Users/ryan/Documents/website-sharkdreams/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/ryan/Documents/website-sharkdreams/node_modules/gatsby-plugin-twitter/gatsby-browser'),
      options: {"plugins":[]},
    }]
